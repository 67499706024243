import AppConstants from "./constants";
import UI from "./ui";
import RequestDispatcher from "./requestHandler";
import User from "./user";

import * as moment from 'moment';
import Oauth from "./oauth";

const Channels = (function () {
  const validateChannelInfo = function () {
    // To check whether the zipcodes have been generated or not
    if ($("#zipcode-checkboxes").has("li").length === 0) {
      UI.showGeneralError("Please generate valid zipcodes");
      return false;
    } else if ($("#zipcode-form-value-changed").val() == 1) {

      // To disallow saving if the zipcodes were changed, but not searched with valid inputs.
      UI.showGeneralError(AppConstants.Messages.ZipcodeChangedNotGenerated);
      return false;
    }

    const timeStamps = $("#ad-message-duration").val();
    const msgtext = $("#ad-message-text").val();

    if ((!timeStamps && msgtext) || (timeStamps && !msgtext)) {
      UI.showGeneralError(
        "Please complete entering details for Message Push"
      );
      console.log("TimeStamps: ", timeStamps, " , Message: ", msgtext);

      // To cancel the operation due to invalid advertisement message
      return false;
    }

    return true;
  };

  const generateChannelFormData = function () {
    if (!validateChannelInfo()) {
      return null;
    }

    /* Validating and generating location.
         Searching for checkboxes selected and adding to locations list. Setting disabled as "BASE"
      */
    const locations = [];
    $("#zipcode-checkboxes")
      .find(":checked")
      .each(function (index) {
        const place = $(this)
          .attr("name")
          .split(",");
        let placeTag;
        if ($(this).prop("disabled")) {
          console.log("disabled");
          placeTag = "BASE";
        } else {
          placeTag = "";
        }
        locations.push({
          zipcode: ($(this)
            .val() as string)
            .substring(0, 5),
          city: place[0],
          state: place[1],
          tag: placeTag
        });
        console.log($(this).val());
      });

    /* Validating and generating Keywords */
    let keywordsList;
    const keywordsText = $("#keywords").val() as string;

    if (keywordsText) {
      // To convert string to list of keywords
      // Filter(item => item): Drop empty elements (in case successive commas are entered).
      // Map(function(item)): Trim leading and trailing white-spaces.
      keywordsList = keywordsText.split(",").filter(item => item).map(function(item){
        return item.trim();
      });
    } else {
      keywordsList = null;
    }

    /* Validating and generating Genre(Category) */
    let genreVal: string = $("#genre").val() as string;

    if (genreVal === "Other") {
      const otherBox = $("#other-text-box").val() as string;

      /* Add Sub-category only if it is non-empty */
      if (otherBox.trim().length > 0) {
        genreVal = `${genreVal} - ${otherBox}`;
      }
    }

    /* Validating and generating Message Advert */
    let messageData = null;
    const timeStamps: string[] = ($("#ad-message-duration")
      .val() as string)
      .split("-");
    const startTime = moment(timeStamps[0], "MM/DD/YYYY hh:mm A").unix() * 1000;
    const endTime = moment(timeStamps[1], "MM/DD/YYYY hh:mm A").unix() * 1000;
    const msgtext: string = $("#ad-message-text").val() as string;

    // TBD : Refactor error checking
    if (startTime && endTime && msgtext) {
      messageData = {
        endTime,
        startTime,
        text: msgtext
      };
    }

    // PREPARE FORM DATA
    const channelFormData = {
      advertisement: {
        message: messageData
      },
      alias: {
        description: $("#description").val()
        // Not updating the alias values for name and thumbnail.Hence, Setting as null.
      },
      category: genreVal,
      content_rating: $("#content-rating").val(),
      description: $("#descriptionYt").val(),
      keywords: keywordsList,
      locations,
      name: $("#name").text(),
      notes: $("#notes").val(),
      restrictions: {
        out_of_zipcode_access: $("#allow-outside-access").is(":checked")
      },
      thumbnail_url: $("#channelLogo").attr("src"),
      thumbnail_url_hq: Oauth.ytChannelThumbnailHQUrl,
      type: $("#type").val(),
      url: $("#url").text()
    };

    const data = JSON.stringify(channelFormData);
    return data;
  };

  return {
    Create: () => {
      const formDataAsString = generateChannelFormData();

      if (formDataAsString) {
        const accessUrl = AppConstants.Create.url.replace("{user_id}", User.getInfo().user_id);

        RequestDispatcher.postWithAuth(accessUrl, formDataAsString, AppConstants.Create, User.getAccessKey())
          .then(response => {
            UI.showPostSuccess(response);
          })
          .catch(error => {
            var response = JSON.parse(JSON.stringify(error));
            if (AppConstants.Forbidden == response.status) {
              UI.sessionExpiredErrorDisplay();
            } else {
              UI.showError(error);
            }
          });
      } else {
        console.debug("Invalid form data");
      }
    },
    Update: () => {
      const formDataAsString = generateChannelFormData();

      if (formDataAsString) {
        const accessUrl = AppConstants.Update.url.replace("{user_id}", User.getInfo().user_id);

        RequestDispatcher.postWithAuth(accessUrl, formDataAsString, AppConstants.Update, User.getAccessKey())
          .then(response => {
            UI.showPostSuccess(response);
          })
          .catch(error => {
            var response = JSON.parse(JSON.stringify(error));
            if (AppConstants.Forbidden == response.status) {
              UI.sessionExpiredErrorDisplay();
            } else {
              UI.showError(error);
            }
          });
      } else {
        console.debug("Invalid form data");
      }
    },
    UpdateState: state => {
      // PREPARE FORM DATA
      const formData = {
        url: $("#url").text()
      };

      let stateVal;
      if (state === AppConstants.UpdateState.activeParameter) {
        stateVal = true;
      } else {
        stateVal = false;
      }

      const accessUrl = `${AppConstants.UpdateState.url.replace(
        "{user_id}",
        User.getInfo().user_id
      )}?active=${stateVal}`;
      const data = JSON.stringify(formData);

      RequestDispatcher.postWithAuth(accessUrl, data, AppConstants.UpdateState, User.getAccessKey())
        .then(response => {
          UI.showPostSuccess(response);
        })
        .catch(error => {
          var response = JSON.parse(JSON.stringify(error));
          if (AppConstants.Forbidden == response.status) {
            UI.sessionExpiredErrorDisplay();
          } else {
            UI.showError(error);
          }
        });
    },
    GetChannelData: (
      channelId,
      onSuccessCompletionHandler,
      onFailureCompletionHandler
    ) => {
      // Dummy parameter added to avoid browser caching in some browsers
      const accessUrl = `${
        AppConstants.GetChannelData.accessUrl +
        channelId
        }?_=${new Date().getTime()}`;

      RequestDispatcher.get(accessUrl)
        .then(result => {
          console.log("Get Channel Success : ", result);

          if (null == result) {
            console.log("Empty response on success, indicates channel not added already.");
            if (onFailureCompletionHandler) {
              onFailureCompletionHandler();
            }
          } else {
            if (onSuccessCompletionHandler) {
              onSuccessCompletionHandler();
            }
          }
        })
        .catch(error => {
          console.log(
            "Get Channel Failed : ",
            JSON.parse(JSON.stringify(error))
          );

          if (onFailureCompletionHandler) {
            onFailureCompletionHandler();
          }
        });
    },
    getZipCodeList: () => {
      let radius = $("#zipCodeRadius").val() as number;
      const zipcodeVal = $("#zipCode").val() as string;

      if (radius > User.getUserConfig().maxRadiusSearchZipcode) {

        UI.showGeneralError(`The limit for zip code search is: ${User.getUserConfig().maxRadiusSearchZipcode} miles`);
      } else {

        //Sanity Check
        if (radius < 0) {
          radius = 0;
        }

        const accessUrl = AppConstants.ZipCodeApi.RadiusApiBaseUrl
          .replace("{base_zipcode}", zipcodeVal)
          .replace("{distance}", radius.toString())
          .replace("{user_id}", User.getInfo().user_id);

        $("#zipcode-checkboxes").hide();
        $("#zipcode-search-loader").show();
        RequestDispatcher.getWithAuth(accessUrl, User.getAccessKey())
          .then((response: ResponseList) => {
            console.log("Get zipcodes with radius : ", response);
            UI.sortAndPopulateZipcodes(response.items, zipcodeVal);
            $("#zipcode-search-loader").hide();
            $("#zipcode-checkboxes").show();
            //RequestDispatcher.resizeView();
          })
          //TBD: To fix
          .catch((error: any) => {
            $("#zipcode-search-loader").hide();
            $("#zipCode").val('');
            $("#zipCodeRadius").val('');
            $("#zipcode-checkboxes").hide();
            var response = JSON.parse(JSON.stringify(error));
            if (AppConstants.Forbidden == response.status) {
              UI.sessionExpiredErrorDisplay();
            } else {
              UI.showError(error);
            }
            //RequestDispatcher.resizeView();
          });
      }
    }
  };
})();

export default Channels;
