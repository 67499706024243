import AppConstants from "./constants";

const RequestDispatcher = (function () {
  return {
    // To get the data for the channel
    get: (accessUrl: string) => {
      console.log(accessUrl);
      return $.ajax({
        url: accessUrl
      });
    },
    getWithAuth: (accessUrl: string, key: string) => {
      console.log(accessUrl);

      if (key) {
        return $.ajax({
          url: accessUrl,
          headers: {
            "CCS-AUTH-TOKEN": key
          }
        });
      } else {
        console.log("Key not provided for request.");
      }

    },
    post: (accessUrl: string, data: string, requestOptions) => {
      console.log(accessUrl, data, requestOptions);

      if (requestOptions) {
        return $.ajax({
          type: requestOptions.httpReqType,
          dataType: "json",
          url: accessUrl,
          contentType: "application/json",
          data
        });
      }
      return null;
    },
    postWithAuth: (accessUrl: string, data: string, requestOptions, key: string) => {
      console.log(accessUrl, data, requestOptions);

      if (key) {
        if (requestOptions) {
          return $.ajax({
            type: requestOptions.httpReqType,
            dataType: "json",
            url: accessUrl,
            contentType: "application/json",
            data,
            headers: {
              "CCS-AUTH-TOKEN": key
            }
          });
        }
      } else {
        console.log("Key not provided for request.");
      }
      return null;
    },
    postWithoutData: (accessUrl, requestOptions) => {
      console.log(accessUrl, requestOptions);

      if (requestOptions) {
        return $.ajax({
          type: requestOptions.httpReqType,
          dataType: "json",
          url: accessUrl
        });
      }
      return null;
    },
    postWithoutDataWithAuth: (accessUrl, requestOptions, key) => {
      console.log(accessUrl, requestOptions);

      if (key) {
        if (requestOptions) {
          return $.ajax({
            type: requestOptions.httpReqType,
            dataType: "json",
            url: accessUrl,
            headers: {
              "CCS-AUTH-TOKEN": key
            }
          });
        }
      } else {
        console.log("Key not provided for request.");
      }
      return null;
    },
    requestUserData: () => {
      window.parent.postMessage(
        { request: "sendUserInfo" },
        AppConstants.ParentDomain
      );
      console.log("Requesting User Data");
    },
    scrollToTop: () => {
      window.parent.postMessage(
        { request: "scrollToTop" },
        AppConstants.ParentDomain
      );
      console.log("Scrolling to Top");
    }
    // ,
    // resizeView: () => {
    //   window.parent.postMessage(
    //     { request: "resize" },
    //       AppConstants.ParentDomain
    //   );
    //   console.log("Resizing view");
    // }
  };
})();

export default RequestDispatcher;
