var config = APP_CONFIG;

function _getHostName(): string {
  return SERVER_URL;
}

const AppConstants = {
  Appearance: {
    Color: {
      PastelRed: "#ff6961",
      LightGreen: "#90EE90",
      Yellow: "#FFFF00"
    },
    ThumbnailDisplay: {
      Height: "248.5px",
      Width: "248.5px"
    }
  },
  Messages: {
    channelsLimitReached: `The limit for adding new channels has been reached. Please hide an active channel to enable addition of new channels.`,
    YtChannelExists: `Youtube Channel has already been added.`,
    YtChannelAddedByAnotherUser: `Youtube Channel has already been added by another User.`,
    YtInvalidChannel: `No Youtube Channel exists for the selected Google Account`,
    ZipcodeUsageLimitReached: `The zip code search service is unavailable at the moment. Please try after an hour.`,
    ZipcodeChangedNotGenerated: `Please click on "Search surrounding zip codes" button before saving, or Press the "Cancel" button to discard your changes.`,
    AtleastOneValueRequired: `Please enter at least one value.`,
    SessionExpired: `Session expired. Please re-load page to log-in again.`
  },
  Cookie: {
    admin: "admin_info_accepted",
    compatibility: "compatibility_accepted",
    studio: "studio_info_accepted",
    generalVal: "true"
  },
  Create: {
    httpReqType: "POST",
    /* 
     * Handle backend API version increment only for Channels Controller.
     * NOTE that User and UserAdmin controller are still at v1.
     * This portal will be compatible only with backend v1.7.0 and higher. 
     * Use ".../v1/channels/..." here in case of compatibility issues.
     */
    url: `${_getHostName()}/api/v2/channels/{user_id}/create`
  },
  Update: {
    httpReqType: "PUT",
    /* 
     * Handle backend API version increment only for Channels Controller.
     * NOTE that User and UserAdmin controller are still at v1.
     * This portal will be compatible only with backend v1.7.0 and higher. 
     * Use ".../v1/channels/..." here in case of compatibility issues.
     */
    url: `${_getHostName()}/api/v2/channels/{user_id}/update/`
  },
  UpdateState: {
    httpReqType: "POST",
    /* 
     * Handle backend API version increment only for Channels Controller.
     * NOTE that User and UserAdmin controller are still at v1.
     * This portal will be compatible only with backend v1.7.0 and higher. 
     * Use ".../v1/channels/..." here in case of compatibility issues.
     */
    url: `${_getHostName()}/api/v2/channels/{user_id}/update/state`,
    inactiveParameter: "INACTIVE",
    activeParameter: "ACTIVE",
    blacklistedParameter: "BLACKLISTED",
    blacklistedChannelParameter: "BLACKLISTED_CHANNEL",
    terminatedParameter: "TERMINATED"
  },
  GetChannelData: {
    /* 
     * Handle backend API version increment only for Channels Controller.
     * NOTE that User and UserAdmin controller are still at v1.
     * This portal will be compatible only with backend v1.7.0 and higher. 
     * Use ".../v1/channels/..." here in case of compatibility issues.
     */
    accessUrl: `${_getHostName()}/api/v2/channels/lookup/youtube/`
  },
  GetChannelsList: {
    /* 
     * Handle backend API version increment only for Channels Controller.
     * NOTE that User and UserAdmin controller are still at v1.
     * This portal will be compatible only with backend v1.7.0 and higher. 
     * Use ".../v1/channels/..." here in case of compatibility issues.
     */
    accessUrl: `${_getHostName()}/api/v2/channels/lookup/user/`
  },
  Youtube: {
    GetYoutubeChannel: {
      baseRequest: "https://www.googleapis.com/youtube/v3/channels"
    },
    BaseUrl: "https://www.youtube.com/channel/"
  },
  User: {
    Create: {
      httpReqType: "POST",
      url: `${_getHostName()}/api/v1/user/create/`
    },
    Get: {
      accessUrl: `${_getHostName()}/api/v1/user/`
    },
    Security: {
      httpReqType: "POST",
      accessUrl: `${_getHostName()}/api/v1/user/auth/{user_id}`
    }
  },
  Admin: {
    Base: `${_getHostName()}/api/v1/admin/`,
    GetUser: {
      accessUrl: `{superUser_id}/lookup/user/`
    },
    GetChannels: {
      accessUrl: `{superUser_id}/lookup/user/{user_id}/channels`
    },
    GlobalConfig: {
      Get: {
        accessUrl: `settings/global/`
      },
      Update: {
        httpReqType: "PUT",
        accessUrl: `{superUser_id}/settings/update/global/`
      }
    },
    UserConfig: {
      Get: {
        accessUrl: `settings/user/{user_id}/`
      },
      Update: {
        httpReqType: "PUT",
        accessUrl: `{superUser_id}/settings/update/user/{user_id}/`
      },
      Create: {
        httpReqType: "POST",
        accessUrl: `{superUser_id}/settings/create/user/{user_id}/`
      },
      Delete: {
        httpReqType: "DELETE",
        accessUrl: `{superUser_id}/settings/delete/user/{user_id}/`
      }
    },
    Blacklist: {
      httpReqType: "POST",
      User: {
        accessUrl: `{superUser_id}/update/user/{user_id}/state/`
      },
      Channel: {
        accessUrl: `{superUser_id}/update/channel/blacklistStatus/`
      }
    },
    Channel: {
      Update: {
        httpReqType: "PUT",
        accessUrl: `{superUser_id}/update/channel/all/`
      },
      State: {
        httpReqType: "POST",
        accessUrl: `{superUser_id}/update/channel/state/`
      }
    }
  },
  Oauth: {
    scope:
      "https://www.googleapis.com/auth/youtube.force-ssl https://www.googleapis.com/auth/youtubepartner",
    discoveryDocs: [
      "https://www.googleapis.com/discovery/v1/apis/youtube/v3/rest"
    ],
    clientID: config.youtube.clientID,
    apiKey: config.youtube.apiKey
  },
  ZipCodeApi: {
    /* 
     * Handle backend API version increment only for Channels Controller.
     * NOTE that User and UserAdmin controller are still at v1.
     * This portal will be compatible only with backend v1.7.0 and higher. 
     * Use ".../v1/channels/..." here in case of compatibility issues.
     */
    RadiusApiBaseUrl: `${_getHostName()}/api/v2/channels/{user_id}/search/zipcode/{base_zipcode}/radius/{distance}`
  },
  ParentDomain: PARENT_DOMAIN_NAME,
  AppVersion: VERSION,
  ThumbnailStandardQuality: "/SQ/",
  ThumbnailHighQuality: "/HQ/",
  ProdEnvironment: "PROD",
  QaEnvironment: "QA",
  IntEnvironment: "INT",
  LocalEnvironment: "LOCAL",
  Environment: config.environment,
  Forbidden: 403
};

export default AppConstants;
