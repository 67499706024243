import AppConstants from "./constants";
import UI from "./ui";
import Channels from "./channels";
import User from "./user";

/* Google OAuth related */
const Oauth = (function () {
  // Global variable for GoogleAuth object.
  let GoogleAuth;

  return {
    /**
     * Retain the HQ thumbnail URL.
     */
    ytChannelThumbnailHQUrl: "",
    /**
     * Load the API's client and auth2 modules.
     * Call the initClient function after the modules load.
     */
    handleClientLoad: () => {
      gapi.load("client:auth2", Oauth.initClient);
    },
    initClient: () => {
      // Initialize the gapi.client object, which app uses to make API requests.
      // Get API key and client ID from API Console.
      // 'scope' field specifies space-delimited list of access scopes
      gapi.client
        .init({
          // apiKey: AppConstants.Oauth.apiKey,
          clientId: AppConstants.Oauth.clientID,
          discoveryDocs: AppConstants.Oauth.discoveryDocs,
          scope: AppConstants.Oauth.scope
        })
        .then(() => {
          GoogleAuth = gapi.auth2.getAuthInstance();

          // Listen for changes to current user.
          GoogleAuth.currentUser.listen(Oauth.userChanged);

          $("#execute-request-button").click(event => {
            event.preventDefault();

            if (!User.checkIfLimitReached()) {
              Oauth.handleAuthClick();
            }
          });
        });
    },
    handleAuthClick: () => {
      // Sign user in after click on auth button.
      GoogleAuth.signIn();
    },
    /**
     * Listener method for when the user changes.
     *
     * @param {GoogleUser} user the updated user.
     */
    userChanged: () => {
      Oauth.refresh();
    },
    refresh: () => {
      const user = GoogleAuth.currentUser.get();
      console.log("User", user);
      const isAuthorized = user.hasGrantedScopes(AppConstants.Oauth.scope);

      if (isAuthorized) {
        Oauth.executeRequest();
      } else {
        console.log("User does not have access.");
      }
    },
    executeRequest: () => {
      /* The extra parameter has been added to differentiate the request if a new user tries to query the same data. This avoids cached responses */
      const accessUrl = `${
        AppConstants.Youtube.GetYoutubeChannel.baseRequest
        }?part=snippet&mine=true&_=${new Date().getTime()}`;

      $.ajax({
        url: accessUrl,
        beforeSend(xhr) {
          xhr.setRequestHeader(
            "Authorization",
            `Bearer ${GoogleAuth.currentUser.get().getAuthResponse().access_token}`
          );
        }
      })
        .then(response => {
          console.log("Youtube Channel Data Retrieved For User", response);

          // Revoking access to prevent sending youtube request again.
          GoogleAuth.disconnect();

          const existingChannelOperation = function() {
            UI.showGeneralError(
              AppConstants.Messages.YtChannelAddedByAnotherUser
            );
          };

          const newChannelOperation = function () {
            const finalUrl = `${
              AppConstants.Youtube.GetYoutubeChannel.baseRequest
              }?part=id&id=${response.items[0].id}&key=${
              AppConstants.Oauth.apiKey
              }&_=${new Date().getTime()}`;

            $.ajax({
              url: finalUrl
            }).then(res => {
              if (res.items.length === 0) {
                UI.showGeneralError(AppConstants.Messages.YtInvalidChannel);
              } else {
                console.log("Valid Youtube Channel");
                UI.clearContentPage();

                const url = AppConstants.Youtube.BaseUrl + response.items[0].id;
                const imageUrl = response.items[0].snippet.thumbnails.medium.url;
                Oauth.ytChannelThumbnailHQUrl = response.items[0].snippet.thumbnails.high.url;
                const name = response.items[0].snippet.title;
                const description = response.items[0].snippet.description;

                $("#channel-id").hide();
                $("#url").text(url);
                $("#channelLogo").attr("src", imageUrl);

                $("#name").text(name);
                $("#name").attr("href", url);

                $("#description").val(description);
                /* Storing the description of the channel as in Youtube */
                $("#descriptionYt").val(description);

                UI.showCreateOperation();
              }
            }).catch(error => {
              console.log(error);
            });
          };
        
          const channelsList = User.getChannelsList();
          let isValidOp = true;

          if (channelsList && channelsList.length > 0) {
            channelsList.forEach(channel => {
              const youtubeUrl =
                AppConstants.Youtube.BaseUrl + response.items[0].id;

              if (youtubeUrl === channel.url) {
                UI.showGeneralError(AppConstants.Messages.YtChannelExists);
                isValidOp = false;
              }
            });
          }

          if (isValidOp) {
            Channels.GetChannelData(
              response.items[0].id,
              existingChannelOperation,
              newChannelOperation
            );
          } else {
            console.log("Invalid Operation for creation of channel.");
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  };
})();

export default Oauth;
