import UI from "./ui";
import RequestDispatcher from "./requestHandler";
import AppConstants from "./constants";

const User = (function () {

  let info: User;

  let channelsList: Channel[];

  let reachedLimit = false;

  let activeChannel: Channel;

  let config: GlobalConfig;

  let accessKey: string;

  return {

    getAccessCredentials: (userInformation) => {

      const userId = userInformation.id;
      const email = userInformation.email;

      const accessUrl = AppConstants.User.Security.accessUrl
        .replace("{user_id}", userId) + "?email=" + email;

      return RequestDispatcher.postWithoutData(encodeURI(accessUrl), AppConstants.User.Security)
        .then(response => {
          accessKey = response.accessKey;
        }).catch((error: any) => {
          console.log(error.responseJSON.reason);
        })
    },
    getAccessKey: () => {
      return accessKey;
    },
    // To get the data for the channel
    getChannels: () => {
      const userId = info.user_id;
      console.log("Attempting to retrieve channels for: ", info);

      if (userId) {
        const urlForChannelsList =
          AppConstants.GetChannelsList.accessUrl + userId;

        RequestDispatcher.getWithAuth(urlForChannelsList, User.getAccessKey())
          .then((result: ResponseList) => {
            console.log("Get Channel List Success : ", result);

            // TBD: May need to refactor usage
            channelsList = result.items;

            UI.generateListContent(channelsList);
            //RequestDispatcher.resizeView();
          })
          .catch(error => {
            var response = JSON.parse(JSON.stringify(error));
            if (AppConstants.Forbidden == response.status) {
              UI.sessionExpiredErrorDisplay();
            } else {
              console.log("Get Channel List Failed : ", response);
            }

            UI.showChannelsList(false);
            //RequestDispatcher.resizeView();
          });
      } else {
        console.log("User is not present. Cannot get channels List", info);
        UI.showUserInfo(false);
      }
    },
    SetCurrentConfig: async (userId: string) => {

      try {
        const globalConfig: GlobalConfig = await User.GetGlobalConfig();

        try {
          const userConfig: GlobalConfig = await User.RetrieveUserConfig(userId);

          let tempConfig = {
            app: {
              newChannelInDays: null,
              localChannelInMiles: null,
              maxChannelVideoSyncCount: null,
              newContentInDays: null,
              zipcodeRefreshIntervalInDays: null
            },
            retailer: {
              channelsLimit: null,
              maxRadiusSearchZipcode: null,
              zipcodesPerChannel: null
            }
          };

          //Assigning default value as userConfig does not contain app config.
          tempConfig.app = globalConfig.app;

          if (userConfig.retailer) {

            tempConfig.retailer.channelsLimit = userConfig.retailer.channelsLimit ? userConfig.retailer.channelsLimit : globalConfig.retailer.channelsLimit;
            tempConfig.retailer.maxRadiusSearchZipcode = userConfig.retailer.maxRadiusSearchZipcode ? userConfig.retailer.maxRadiusSearchZipcode : globalConfig.retailer.maxRadiusSearchZipcode;
            tempConfig.retailer.zipcodesPerChannel = userConfig.retailer.zipcodesPerChannel ? userConfig.retailer.zipcodesPerChannel : globalConfig.retailer.zipcodesPerChannel;
          } else {
            tempConfig.retailer = globalConfig.retailer;
          }

          config = tempConfig;
        } catch (error) {
          config = globalConfig;
        }

        return Promise.resolve(config);
      } catch (e) {
        console.log("Could not assign any config for user");
        return Promise.reject(e);
      }

    },
    GetGlobalConfig: async () => {

      const accessUrl =
        AppConstants.Admin.Base +
        AppConstants.Admin.GlobalConfig.Get.accessUrl;

      try {
        const response = await RequestDispatcher.get(accessUrl);
        console.log("Global config received as :", response);
        return Promise.resolve(response);
      }
      catch (error) {
        console.log("Could not fetch Global Config");
        return Promise.reject(error);
      }
    },
    RetrieveUserConfig: async (userId: string) => {

      const accessUrl =
        AppConstants.Admin.Base +
        AppConstants.Admin.UserConfig.Get.accessUrl
          .replace("{user_id}", userId);

      try {
        const response = await RequestDispatcher.get(accessUrl);
        console.log("User config received as :", response);
        return Promise.resolve(response);
      }
      catch (error) {
        console.log(`Could not fetch Config for user - ${userId}`);
        return Promise.reject(error);
      }
    },
    getInfo: () => info,
    getChannelsList: () => channelsList,
    getUserConfig: () => config.retailer,
    ValidateUserInfo: userInformation => {
      const userId = userInformation.id;

      const successCompletionHandler = function (userInfo) {

        //To set the Max Radius Limit.
        UI.setMaxRadiusLimit();

        // Get User Information
        UI.loadUserInfo(userInfo);

        UI.showUserInfo(true);

        // Get Channels Data for retailer
        User.getChannels();
        //RequestDispatcher.resizeView();
      };

      User.getRetailerInfo(userId)
        .then(userInfo => {

          User.SetCurrentConfig(userId).then(() => successCompletionHandler(userInfo));
        })
        .catch(() => {
          User.Create(userInformation).then(() => {
            User.getRetailerInfo(userId).then(userInfo => {
              User.SetCurrentConfig(userId).then(() => successCompletionHandler(userInfo));
            });
          });
        });
    },
    /* Providing user information and a flag whether to create on failure */
    getRetailerInfo: (userId: string) => {
      // Dummy parameter added to avoid browser caching in some browsers
      const accessUrl = `${AppConstants.User.Get.accessUrl +
        userId}?_=${new Date().getTime()}`;

      return RequestDispatcher.getWithAuth(accessUrl, User.getAccessKey())
        .then(result => {
          console.log("Get User Success : ", result);

          info = result;
          return Promise.resolve(result);
        })
        .catch(error => {
          var response = JSON.parse(JSON.stringify(error));
          if (AppConstants.Forbidden == response.status) {
            UI.sessionExpiredErrorDisplay();
          } else {
            console.log("Get User Failed : ", response);
          }
          return Promise.reject(error);
        });
    },
    // TBD: Refactor
    setActiveChannel: (channel: Channel) => {
      activeChannel = channel;
    },
    // TBD: Refactor
    setLimitReached: (flag: boolean) => {
      reachedLimit = flag;
    },
    // TBD: Refactor
    checkIfLimitReached: () => reachedLimit,
    checkChannelActive: () =>
      activeChannel &&
      activeChannel.state === AppConstants.UpdateState.activeParameter,
    checkChannelTerminated: () =>
      activeChannel &&
      activeChannel.state === AppConstants.UpdateState.terminatedParameter,
    Create: userData => {
      console.log("Attempting to create new user : ");

      let imageInfo = null;
      if (Array.isArray(userData.photos) && userData.photos.length > 0) {
        imageInfo = userData.photos[0].value;
      }

      // PREPARE FORM DATA
      const formData = {
        user_id: userData.id,
        name: userData.name,
        email: userData.email,
        imageUrl: imageInfo,
        userName: userData.username
      };

      const accessUrl = AppConstants.User.Create.url;
      const data = JSON.stringify(formData);

      return RequestDispatcher.post(accessUrl, data, AppConstants.User.Create)
        .then(response => {
          console.log("Created User :", response);
        })
        .catch(error => {
          console.log(
            "User Could Not Be Created :",
            JSON.parse(JSON.stringify(error))
          );
        });
    }
  };
})();

export default User;
