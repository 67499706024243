import Oauth from "./oauth";
import Channel from "./channels";
import UI from "./ui";
import User from "./user";
import AppConstants from "./constants";
import RequestDispatcher from "./requestHandler";

import * as moment from 'moment';

$(document).ready(() => {
  /* Setting app version through package.json */
  $("#version").html(`v${AppConstants.AppVersion}`);

  // Default Image for the channel Logo , in case the image is not available
  $("#channelLogo").on("error", () => {
    $("#channelLogo").attr("src", "images/channel-default.jpg");
  });

  //Check for chrome browser. May show false positive for chrome/chromium derivatives.
  var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor) && !!(window as any).chrome;

  const cookieInfo = AppConstants.Cookie;

  if (_getCookie(cookieInfo.compatibility) != cookieInfo.generalVal) {
    if (!isChrome) {
      $("#compatibility-alert").show();
    }
  }

  if (_getCookie(cookieInfo.admin) != cookieInfo.generalVal) {
    $("#additional-help-alert").show();
  }

  if (_getCookie(cookieInfo.studio) != cookieInfo.generalVal) {
    $("#studio-info-alert").show();
  }

  $(".goto-home").click(event => {
    event.preventDefault();
    UI.showHomePage();
  });

  //TODO: Using to validate the other input form.
  $("#createForm").submit(event => {
    event.preventDefault();
    Channel.Update();
  });

  // To allow submitting the form from a btn outside the form
  $("#create-external-submit").click(event => {
    event.preventDefault();
    Channel.Create();
  });

  $("#remove-add-btn").click(event => {
    event.preventDefault();

    if (User.checkChannelActive()) {
      Channel.UpdateState(AppConstants.UpdateState.inactiveParameter);
    } else if (User.checkIfLimitReached()) {
      UI.showGeneralError(AppConstants.Messages.channelsLimitReached);
    } else {
      Channel.UpdateState(AppConstants.UpdateState.activeParameter);
    }
  });

  $('#compatibility-alert').on('close.bs.alert', function () {

    console.log("Storing a cookie for compatibility");
    _setCookie(cookieInfo.compatibility, cookieInfo.generalVal, 365);
  });

  $('#additional-help-alert').on('close.bs.alert', function () {

    console.log("Storing a cookie for admin");
    _setCookie(cookieInfo.admin, cookieInfo.generalVal, 365);
  });

  $('#studio-info-alert').on('close.bs.alert', function () {

    console.log("Storing a cookie for studio");
    _setCookie(cookieInfo.studio, cookieInfo.generalVal, 365);
  });

  $("#genre").change(event => {
    event.preventDefault();
    UI.handleGenreClick();
  });

  $("#zipCode").change(event => {
    event.preventDefault();
    UI.onZipcodeValueChange();
  });

  $("#zipCodeRadius").change(event => {
    event.preventDefault();
    UI.onZipcodeRadiusChange();
  });

  $("#other-text-box").on("keyup", UI.setOtherGenreCharCount);

  $("#keywords").on("keyup", UI.setKeywordsCharCount);

  $("#zipcode-Search").submit(event => {
    event.preventDefault();
    Channel.getZipCodeList();
  });

  $("#description").on("keyup", () => {
    UI.setDescriptionCharCount();
  });

  /* To initialize tooltips */
  $('[data-toggle="tooltip"]').tooltip({
    placement: "top"
  });

  $("#content-rating").change(event => {
    event.preventDefault();
    UI.setContentRatingHelpText();
  });

  $('input[name="datetimes"]').daterangepicker({
    minDate: moment(),
    timePicker: true,
    locale: {
      cancelLabel: "Clear"
    },
    autoUpdateInput: false
  });

  $('input[name="datetimes"]').on("apply.daterangepicker", function (
    ev,
    picker
  ) {
    $(this).val(
      `${picker.startDate.format(
        "MM/DD/YYYY hh:mm A"
      )} - ${picker.endDate.format("MM/DD/YYYY hh:mm A")}`
    );
  });

  $('input[name="datetimes"]').on("cancel.daterangepicker", function (
    ev,
    picker
  ) {
    $(this).val("");
  });

  // To go back to the home page regardless of where user clicks after modal for successful operation appears
  $("#modal-success").on("hidden.bs.modal", UI.showHomePage);

  window.addEventListener(
    "message",
    //TBD: Temp fix
    (event: any) => {
      const origin: string = event.origin || event.originalEvent.origin;
      if (origin !== AppConstants.ParentDomain) {
        return false;
      }

      console.log(event);
      if (event.data.request && event.data.request === "retrieveUserInfo") {
        console.log("User Info received: ", event.data.userInfo);

        User.getAccessCredentials(event.data.userInfo).then(() => {
          User.ValidateUserInfo(event.data.userInfo);
        }).catch((error: any) => {
          // console.log(AppConstants.Environment);
          if (AppConstants.ProdEnvironment.toLowerCase() === AppConstants.Environment.toLowerCase()) {
            console.log("User doesn't exist. Creating new user: " + event.data.userInfo.id + "," + event.data.userInfo.email);
            User.Create(event.data.userInfo).then(() => {
              User.getAccessCredentials(event.data.userInfo).then(() => {
                User.ValidateUserInfo(event.data.userInfo);
              });
            });
          }
        });
      }
    },
    false
  );

  RequestDispatcher.requestUserData();
});

module.exports = {
  googleOnLoad() {
    Oauth.handleClientLoad();
  }
};

function _getCookie(key: string): string {
  var nameEQ = key + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1);
    if (c.indexOf(nameEQ) != -1) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

function _setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}