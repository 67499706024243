import User from "./user";
import RequestDispatcher from "./requestHandler";
import AppConstants from "./constants";

import * as moment from 'moment';

const UI = (function () {
  const populateZipcodes = function (zipCodes, baseZipcode) {
    // Clear existing data
    $(".checkboxes").html("");
    $.each(zipCodes, (index: number, value) => {

      if (value.zipcode == baseZipcode) {
        $(".checkboxes").prepend(
          `<li><div class="checkbox checkbox-primary"><input class="base-zipcode" type="checkbox" name="${
          value.city
          },${value.state}" value=${
          value.zipcode
          } disabled checked="checked">${value.city}, ${value.state} (${
          value.zipcode
          }) </div></li>`
        );
        $(".show-later").css("display", "inline");
      } else {
        $(".checkboxes").append(
          `<li><div class="checkbox checkbox-primary"><input class="other-zipcodes" type="checkbox" name="${
          value.city
          },${value.state}" value=${value.zipcode}>${value.city}, ${
          value.state
          } (${value.zipcode}) </div></li>`
        );
      }
    });

    $('.other-zipcodes').change(_zipcodeSelectionChangeHandler);
    _zipcodeSelectionChangeHandler();
  };

  const prefillZipcodes = function (locations) {

    // Clear existing data
    $(".checkboxes").html("");
    $.each(locations, (index: number, value) => {

      if (value.tag === "BASE") {
        $(".checkboxes").prepend(
          `<li><div class="checkbox checkbox-primary"><input class="base-zipcode" type="checkbox" name="${
          value.city
          },${value.state}" value=${value.zipcode} disabled checked="checked">${
          value.city
          }, ${value.state} (${value.zipcode}) </div></li>`
        );
        $(".show-later").css("display", "inline");
      } else {
        $(".checkboxes").append(
          `<li><div class="checkbox checkbox-primary"><input class="other-zipcodes" type="checkbox" name="${
          value.city
          },${value.state}" value=${value.zipcode} checked="checked">${
          value.city
          }, ${value.state} (${value.zipcode}) </div></li>`
        );
      }
    });

    $('.other-zipcodes').change(_zipcodeSelectionChangeHandler);
    _zipcodeSelectionChangeHandler();
  };

  const _showZipcodeSelectionLimitMsg = function () {

    $("#zipcode-selection-max-value").html(User.getUserConfig().zipcodesPerChannel.toString());
    $("#zipcodes-selection-limit-msg").show();
  };

  const _hideZipcodeSelectionLimitMsg = function () {

    $("#zipcodes-selection-limit-msg").hide();
    $("#zipcode-selection-max-value").html("");
  };

  //To refactor
  //Event handler for managing the zipcode to be selected.
  const _zipcodeSelectionChangeHandler = function () {

    //To exclude base zipcode while calculating limit
    const additionalZipcodesLimit = User.getUserConfig().zipcodesPerChannel - 1;

    if ($('.other-zipcodes:checkbox:checked').length >= additionalZipcodesLimit) {

      $(".other-zipcodes").not(":checked").prop("disabled", true);
      _showZipcodeSelectionLimitMsg();

    } else {

      _hideZipcodeSelectionLimitMsg();
      $(".other-zipcodes").not(":checked").prop("disabled", false);
    }

  }

  // public methods
  return {
    sortAndPopulateZipcodes: (zipCodes: any[], baseZipcode) => {
      //TODO: Refactor.
      populateZipcodes(zipCodes, baseZipcode);

      //To indicate that the operation has been completed.
      UI.unsetZipcodeChangded();
    },
    setMaxRadiusLimit: () => {
      $("#radius-max-value").html(User.getUserConfig().maxRadiusSearchZipcode.toString());
    },
    showError: data => {
      RequestDispatcher.scrollToTop();

      $(".modal-body").html(`<strong>${data.responseJSON.reason} </strong>`);
      $("#modal-failure").modal("show");
      console.log("ERROR: ", data);
    },
    showGeneralError: error => {
      RequestDispatcher.scrollToTop();

      $(".modal-body").html(`<strong>${error} </strong>`);
      $("#modal-failure").modal("show");
      console.log("ERROR: ", error);
    },
    showPostSuccess: data => {
      RequestDispatcher.scrollToTop();

      $(".modal-body").html(`<strong>${data.reason} </strong>`);
      $("#modal-success").modal("show");
      console.log("Success : ", data);
    },
    updateChannelData: (channelData: Channel) => {
      console.log("Loading with channel data :", channelData);

      const id = channelData.code;

      let description;
      if (channelData.alias) {
        if (
          channelData.alias.description &&
          channelData.alias.description.length !== 0
        ) {
          description = channelData.alias.description;
        } else {
          description = channelData.description;
        }
      } else {
        description = channelData.description;
      }

      // TBD: Not using custom thumbnail image or name. May be added/completely removed later
      const image = channelData.thumbnail_url;
      const name = channelData.name;

      // Set to default values if no alias is present

      const url = channelData.url;
      const rating = channelData.content_rating;
      let channelGenre = channelData.category;
      let keywords;
      if (channelData.keywords) {
        keywords = channelData.keywords.join(",");
      }
      let otherType;
      if (channelGenre.indexOf("Other") >= 0) {
        otherType = channelGenre.substring(8);
        channelGenre = "Other";
      }

      let baseLocation;
      channelData.locations.forEach(location => {
        if (location.tag === "BASE") {
          baseLocation = location;
          return false;
        }
      });
      prefillZipcodes(channelData.locations);

      const outsideAccess = channelData.restrictions.out_of_zipcode_access;
      const descriptionYt = channelData.description;

      const additionalNotes = channelData.notes;

      $("#zipCode").val(baseLocation.zipcode);
      $("#zipCodeRadius").val(0);
      $("#content-rating").val(rating);
      $("#genre").val(channelGenre);
      if (channelGenre === "Other") {
        $("#other-text-box").val(otherType);
        $("#sub-category-container").show();
      }
      $("#keywords").val(keywords);
      $("#description").val(description);
      $("#notes").val(additionalNotes);

      $("#channel-id").show();
      $("#channel-id").text(`#${id}`);
      $("#url").text(url);

      /* TBD: Checks for optional values . To be refactored */
      if (channelData.advertisement) {
        const advertisementMsg = channelData.advertisement.message;
        if (advertisementMsg) {
          $("#ad-message-text").val(advertisementMsg.text);
          $("#ad-message-duration").val(
            `${moment(new Date(advertisementMsg.startTime)).format(
              "MM/DD/YYYY hh:mm A"
            )} - ${moment(new Date(advertisementMsg.endTime)).format(
              "MM/DD/YYYY hh:mm A"
            )}`
          );
        }
      }

      // For default values from YT channel
      $("#descriptionYt").val(descriptionYt);

      // Using the default value for name only.
      $("#name").text(name);
      $("#name").attr("href", url);

      (document.getElementById("channelLogo") as HTMLImageElement).src = image;
      $("#allow-outside-access").prop("checked", outsideAccess);

      if (channelData.state === AppConstants.UpdateState.blacklistedParameter) {
        $("#remove-add-btn").prop("disabled", true);
        $("#blacklist-channel-help-text").show();
      } else if (channelData.state === AppConstants.UpdateState.terminatedParameter) {
        $("#blacklist-btn").html("Blacklist");
        $("#blacklist-btn").prop("disabled", true);
        $("#remove-add-btn").prop("disabled", true);
      } else {
        $("#remove-add-btn").prop("disabled", false);
        $("#blacklist-channel-help-text").hide();
      }
    },
    loadUserInfo: userInfo => {
      console.log("Loading User Info Element With Data: ", userInfo);

      const rowContainer = document.createElement("div");
      rowContainer.setAttribute("class", "row");

      const content = document.createElement("div");
      content.setAttribute("id", "profile-photo");
      content.setAttribute("style", "z-index:0;position:relative;");

      let userImageContainer = `<img src="${userInfo.imageUrl}" alt="${
        userInfo.name
        }" onerror="this.src='images/retailer-default.jpg'"/>`;

      if (userInfo.state === AppConstants.UpdateState.blacklistedParameter) {
        userImageContainer += `<div class=".removedImage" style="position:absolute;left:15px;top:10px;z-index:1;">
                  <img src="images/blacklisted.png" style="width:65%;opacity:0.7;">
                 </div>`;

        $("#blacklist-user-help-text").show();
        UI.disableAddBtn();
      } else {
        $("#blacklist-user-help-text").hide();
        UI.enableAddBtn();
      }

      content.innerHTML = userImageContainer;

      let column = document.createElement("div");
      column.setAttribute("class", "column");
      column.appendChild(content);

      rowContainer.appendChild(column);

      column = document.createElement("div");
      column.setAttribute("class", "column");
      column.innerHTML = `<label>Name:</label>
      <span id="retailer-name">${userInfo.name}</span><br/>
      <label>Email:</label>
      <span id="retailer-email">${userInfo.email}</span><br/>
      <label>Retailer Code:</label>
      <span id="retailer-code">${userInfo.user_id}</span><br/>
      <label>Number of active channels:</label>
      <span id="retailer-channels-count">0/${User.getUserConfig().channelsLimit}</span><br/>`;

      rowContainer.appendChild(column);

      $("#profile-info").empty();
      $("#profile-info").append(rowContainer);
    },
    showChannelsList: flag => {
      if (flag) {
        $("#channel-container").show();
        console.log("Showing channels list");
      } else {
        $("#channel-container").hide();
        console.log("Hiding channels list");
      }
    },
    showUserInfo: flag => {
      if (flag) {
        // Unhide the retailer and channel info tabs after acquiring the data
        $("#home").show();
        console.log("Showing User Info");
      } else {
        $("#home").hide();
        console.log("Hiding User Info");
      }
    },
    updateChannelsCount: activeChannelsCount => {
      const limit = User.getUserConfig().channelsLimit

      if (activeChannelsCount >= limit) {
        UI.disableAddBtn();
        $("#limit-exceeded-msg").show();

        User.setLimitReached(true);
      } else {
        UI.enableAddBtn();
        $("#limit-exceeded-msg").hide();

        User.setLimitReached(false);
      }

      $("#retailer-channels-count").text(`${activeChannelsCount}/${limit}`);
    },
    generateListContent: channelsList => {
      UI.showChannelsList(true);

      // Clearing the channels list
      $("#thumbnail-list").empty();

      let rowContainer = document.createElement("div");
      rowContainer.setAttribute("class", "row");

      let index = 0;
      let inActiveCounter = 0;
      channelsList.forEach((originalChannel: Channel) => {
        let image;
        let tileName;

        if (originalChannel.alias) {
          if (
            originalChannel.alias.thumbnail_url &&
            originalChannel.alias.thumbnail_url.length !== 0
          ) {
            image = originalChannel.alias.thumbnail_url;
          } else if (originalChannel.thumbnail_url) {
            image = originalChannel.thumbnail_url;
          } else {
            // Default value to show default image incase of failure
            image = "";
          }

          if (
            originalChannel.alias.name &&
            originalChannel.alias.name.length !== 0
          ) {
            tileName = originalChannel.alias.name;
          } else {
            tileName = originalChannel.name;
          }
        } else {
          if (originalChannel.thumbnail_url) {
            image = originalChannel.thumbnail_url;
          } else {
            // Default value to show default image incase of failure
            image = "";
          }

          tileName = originalChannel.name;
        }

        let videoCount = originalChannel.videoStatistics ? originalChannel.videoStatistics.count : 0;
        let videoViewCount = originalChannel.videoStatistics ? originalChannel.videoStatistics.viewCount : 0;

        if (originalChannel.state !== "ACTIVE") {
          inActiveCounter += 1;
        }

        const channel = {
          id: originalChannel.code,
          image,
          name: tileName,
          state: originalChannel.state,
          video: {
            count: videoCount,
            viewCount: videoViewCount
          }
        };

        console.log("Loading the channel tile with data:", channel);

        const content = document.createElement("div");
        content.setAttribute("class", "content");
        content.setAttribute("id", channel.id);
        content.setAttribute(
          "style",
          "z-index:0;position:relative;cursor:pointer;"
        );

        let thumbnailContainer = `<img src="${channel.image}" alt="${
          channel.name
          }" onerror="this.src='images/channel-default.jpg'"
             style="width:100%;height:${AppConstants.Appearance.ThumbnailDisplay.Height}">`;

        if (channel.state === AppConstants.UpdateState.inactiveParameter) {
          /* The values for top=10px and left=1px are generated by trial and error, for centering image and responsive */
          thumbnailContainer += `<div class=".removedImage" style="position:absolute;left:1px;top:10px;z-index:1;">
              <img src="images/Removed.png" style="width:75%;opacity:0.7;">
             </div>`;
        } else if (channel.state === AppConstants.UpdateState.blacklistedParameter) {
          thumbnailContainer += `<div class=".removedImage" style="position:absolute;left:12px;top:30px;z-index:1;">
              <img src="images/blacklisted.png" style="width:75%;opacity:0.7;">
             </div>`;
        } else if (channel.state === AppConstants.UpdateState.terminatedParameter) {
          /* The values for top=35px and left=1px are generated by trial and error, for centering image and responsive */
          thumbnailContainer += `<div class=".removedImage" style="position:absolute;left:1px;top:35px;z-index:1;">
              <img src="images/Terminated.png" style="width:75%;opacity:0.7;">
             </div>`;
        }

        thumbnailContainer += `<label style="cursor:pointer;">${
          channel.id
          }</label>
          <p>${channel.name}</p>
          <p>Video Count: ${channel.video.count}</p>
          <p>Video View Count: ${channel.video.viewCount}</p>`;

        content.onclick = function () {
          UI.clearContentPage();
          UI.updateChannelData(originalChannel);

          UI.showUpdateOperation();

          // To set the active channel when clicked.
          User.setActiveChannel(originalChannel);

          if (User.checkChannelActive()) {
            $("#remove-add-btn").html("Hide");
          } else if (User.checkChannelTerminated()) {
            $("#remove-add-btn").html("Hide");
            $("#remove-add-btn").prop("disabled", true);
          } else {
            $("#remove-add-btn").html("Unhide");
          }
          //RequestDispatcher.resizeView();
        };

        // To reset the active channel.
        User.setActiveChannel(null);

        content.innerHTML = thumbnailContainer;

        const column = document.createElement("div");
        column.setAttribute("class", "column");
        column.appendChild(content);

        rowContainer.appendChild(column);

        index += 1;
        // To push the row and create another if it reaches the limit of 4 elements per row
        if (index % 4 === 0) {
          $("#thumbnail-list").append(rowContainer);
          // Reseting the value of the row element.
          rowContainer = document.createElement("div");
          rowContainer.setAttribute("class", "row");
        }
      });

      // To  add any leftover elements to the main list
      if (index % 4 !== 0) {
        $("#thumbnail-list").append(rowContainer);
      }

      UI.updateChannelsCount(channelsList.length - inActiveCounter);
      //RequestDispatcher.resizeView();
    },
    showHomePage: () => {
      $("#mainContent").hide();
      $("#mainContainer").show();

      User.GetGlobalConfig();

      /* To refresh the channels list , if the retailer info is shown */
      if ($("#home").is(":hidden") === false) {
        // TBD: May need to call from elsewhere
        User.getChannels();
      }

      RequestDispatcher.scrollToTop();
      console.log("Showing the home page content");
    },
    showContentPage: () => {
      $("#mainContainer").hide();
      $("#mainContent").show();

      UI.setDescriptionCharCount();
      UI.setContentRatingHelpText();
      UI.setOtherGenreCharCount();
      UI.setKeywordsCharCount();

      /*To show the other checkbox only when required */
      UI.handleGenreClick();

      RequestDispatcher.scrollToTop();
      console.log("Showing the channel content page");
      //RequestDispatcher.resizeView();
    },
    showCreateOperation: () => {
      $("#update-button-group").hide();
      $("#button-group").show();

      UI.showContentPage();
    },
    showUpdateOperation: () => {
      $("#button-group").hide();
      $("#update-button-group").show();

      UI.showContentPage();
    },
    clearContentPage: () => {
      /* The channel content is stored in different forms for clearing and placement in html */

      // To reset the content page' data
      ($("#createForm")[0] as HTMLFormElement).reset();
      ($("#zipcode-Search")[0] as HTMLFormElement).reset();
      $("#name").text("");
      $("#url").text("");
      $("#channel-id").text("");
      // For performance benefit using plain js
      (document.getElementById("channelLogo") as HTMLImageElement).src = "#";

      /* To clear zipcode form related data */
      $(".checkboxes").html("");
      $(".show-later").css("display", "none");

      // Clearing additional forms.
      ($("#advertisementForm")[0] as HTMLFormElement).reset();
      ($("#internalDataForm")[0] as HTMLFormElement).reset();

      // TBD: Currently manually clearing. Need to refactor
      $("#banner-image-info-text-1").html("");
      $("#banner-image-info-text-2").html("");
      $("#banner-image-info-text-3").html("");
      $("#banner-image-1").val("");
      $("#banner-image-2").val("");
      $("#banner-image-3").val("");

      //To clear the zipcode based error messages
      UI.onZipcodeRadiusChange();
      _hideZipcodeSelectionLimitMsg();

      //To reset the state of the zipcode change state
      UI.unsetZipcodeChangded();

      console.log("Clearing channel content page");
    },
    handleGenreClick: () => {
      if ($("#genre").val() === "Other") {
        $("#other-text-box").css("display", "inline");
        $("#sub-category-container").show();
        //RequestDispatcher.resizeView();
      } else {
        $("#other-text-box").css("display", "none");
        $("#sub-category-container").hide();
      }
    },
    setContentRatingHelpText: () => {
      if ($("#content-rating").val() === "NC-17") {
        $("#content-rating-help-text").text(
          `Channel will be blocked when a Hopper user sets ‘Hide Adult Programs’ to ON`
        );
      } else {
        $("#content-rating-help-text").text("");
      }
    },
    setDescriptionCharCount: () => {
      const maxlen = $("#description").attr("maxlength") as String;
      const currentCount = $("#description").val() as String;
      //Converting string to number.
      let remainingChar = +maxlen;

      if (currentCount) {
        remainingChar = +maxlen - currentCount.length;
      }
      $("#description-remaining-chars").text(
        `Characters remaining are: ${remainingChar}`
      );
    },
    setOtherGenreCharCount: () => {
      const maxlen = $("#other-text-box").attr("maxlength") as String;
      const currentCount = $("#other-text-box").val() as String;
      //Converting string to number.
      let remainingChar = +maxlen;

      if (currentCount) {
        remainingChar = +maxlen - currentCount.length;
      }
      $("#other-genre-remaining-chars").text(
        `Characters remaining are: ${remainingChar}`
      );
    },
    setKeywordsCharCount: () => {
      const maxlen = $("#keywords").attr("maxlength") as String;
      const currentCount = $("#keywords").val() as String;
      //Converting string to number.
      let remainingChar = +maxlen;

      if (currentCount) {
        remainingChar = +maxlen - currentCount.length;
      }
      $("#keywords-remaining-chars").text(
        `Characters remaining are: ${remainingChar}`
      );
    },
    onZipcodeRadiusChange: () => {

      let currentRadius = $("#zipCodeRadius").val() as number;

      if (currentRadius > User.getUserConfig().maxRadiusSearchZipcode) {
        $("#radius-limit-msg").show();
      } else {
        $("#radius-limit-msg").hide();
      }

      UI.setZipcodeChanged();
    },
    onZipcodeValueChange: () => {

      UI.setZipcodeChanged();
    },
    /**
     * To store the state if the zipcode state has been changed
     */
    setZipcodeChanged: () => {
      $("#zipcode-form-value-changed").val(1);
    },
    /**
     * To unset the state of the zipcode form changed state
     */
    unsetZipcodeChangded: () => {
      $("#zipcode-form-value-changed").val(0);
    },
    disableAddBtn: () => {
      $("#execute-request-button").prop("disabled", true);
    },
    enableAddBtn: () => {
      $("#execute-request-button").prop("disabled", false);
    },
    sessionExpiredErrorDisplay: () => {
      console.log("Hiding portal.");
      $("#youtubeData").hide();
      $("#channelDataContainer").hide();
      UI.showGeneralError(AppConstants.Messages.SessionExpired);
    }  
  };
})();

export default UI;
